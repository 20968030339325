const { register } = require("@/api");

//阿拉伯语
module.exports = {
    all: {
        success: 'عملية ناجحة',
        error: 'فشل',
        cancel: 'ألغى',
        confirm: 'أكّد',
        tip: 'جديلة',
        lackBalance: 'الرصيد غير كاف',
        withdrawPwdErr: 'خطأ في سحب كلمة المرور',
        notComplete: 'غير المكتملة تتطلب المفرد',
        notMessage: 'معلومات عن سحب غير مقيد من جانب المستعمل'
    },
    login:{
        login: 'سجل',
        register: 'التسجيل',
        placeholder1: 'الرجاء إدخال رقم تسجيل الدخول',
        placeholder2: 'الرجاء إدخال كلمة السر'
    },
    register: {
        invitationCode: 'رمز الدعوة',
        phone: 'رقم الجوال',
        password: 'كلمة السر',
        confirmPassword: 'تأكيد كلمة المرور',
        withdrawalPassword: 'استخراج كلمة السر',
        login: 'سجل',
        register: 'التسجيل'
    },
      // 首页
      index: {
        myService: 'خدمة بلدي',
        download: 'للتحميل',
        help: 'ساعد',
        partners: 'شريك',
        selectLanguage: 'اختيار اللغة',
        incomeCommission: 'لجنة الإيرادات'
    },
    help: {
        title: "ساعد",
        question1: "1.Amazon AmazonBOTماذا؟?",
        question2: "AmazonBOT هي شركة تسويق وترويج تساعد تجار Amazon.com في جميع أنحاء العالم في الحصول على المزيد من المبيعات على طلباتهم وزيادة بيانات التصفح على متجر Amazon عبر الإنترنت. نحن ملتزمون بإنشاء نموذج ترويج تسويقي مربح ثلاثي الاتجاهات بين Amazon والتجار والمستهلكين.",
        question3: "إلى جانب أحدث تقنيات blockchain P2P ، فإن سياسة العمل لربط المستهلكين والتجار بسرعة من خلال USDT (TRC20 ، ERC20). يحصل المستخدمون المسجلون على عمولات على الطلبات ، بينما يزيد التجار من بيانات مبيعات المتجر.أحدث نموذج ربح بموجب نموذج blockchain للإنترنت!",
        question4: "2.كيف يعمل Amazon AmazonBOT؟",
        question5: "تُظهر ملاحظات Amazon اليومية المنتجات في مبيعات Amazon التي تحتاج إلى زيادة المبيعات. يحتاج المستخدم فقط إلى النقر فوق الطلب بنقرة واحدة ، وسيقوم النظام تلقائيًا بإنشاء اشتراك الطلب.يدفع المستخدمون مبلغ الطلب من خلال blockchain USDT ويحصلون على عمولة يومية .",
        question6: "3.لماذا يوجد فرق في سعر العملة نفسها بين عمليتين؟",
        question7: "يمكن أن تؤدي جميع العوامل التي لا تساعد على التدفق الحر للعملة إلى اختلافات الأسعار ، وتشمل هذه العوامل سرعة تحويل العملة ، وظروف الشبكة ، وقيود الوصول إلى العملة ، والاعتراف بالعملة من قبل الأشخاص في مناطق مختلفة ، وحتى أنواع المعاملات التي تقدمها البورصات ، المعاملات ، إلخ. لذلك ، قد يكون للعملة نفسها فروق أسعار في معاملات مختلفة.",
        question8: "4.ربح الاستثمار?",
        question9: "كلما ارتفع سعر العنصر الذي تحصل عليه ، زاد ربح الطلب الذي تحصل عليه.في نفس الوقت ، تقوم AmazonBOT بتوزيع طلبات العمولات الكبيرة بشكل عشوائي.",
        trad: "قواعد التداول",
        trad1: "يقوم النظام تلقائيًا بإنشاء وتوزيع 60 طلب سلعة للمستخدمين كل يوم ، ويكمل المستخدمون دفع كل طلب بعملة USDT ويحصلون على عمولة بنسبة 0.5٪ ، ويقوم النظام بشكل عشوائي بتعيين أوامر العمولات الكبيرة.",
        trad2: "يمكن سحب USDT بعد إكمال 60 طلبًا (إذا لم يتم إكمال 60 طلبًا ، فسيتوقف النظام تلقائيًا حتى وصول عدد الطلبات المتبقية في اليوم.）",
        top_up: "كيفية إعادة الشحن?",
        top_up1: "قبل إعادة الشحن ، يرجى ربط عنوان السحب الخاص بك (دعم TRC-20) عنوان USDT ، يمكنك الانتقال إلى التطبيق أو صفحة الويب والنقر فوق إعادة الشحن ، وتحديد blockchain (TRC-20) الذي تستخدمه لإعادة الشحن.",
        withdraw: "كيفية سحب الأموال?",
        withdraw1: "قبل إعادة الشحن ، يرجى ربط عنوان السحب الخاص بك (دعم TRC-20) عنوان USDT ، يمكنك الانتقال إلى التطبيق أو صفحة الويب والنقر فوق إعادة الشحن ، وتحديد blockchain (TRC-20) الذي تستخدمه لإعادة الشحن.",
        invite: "كيفية دعوة الأصدقاء?",
        invite1: "يمكنك دعوتهم عبر رابط الدعوة أو رمز الدعوة وسيصبحون أعضاء فريقك بعد التسجيل الناجح.",
        invite2: "عندما تدعو أصدقاء للانضمام ، يمكنك كسب 30٪ من الدخل اليومي للأصدقاء ذوي المستوى المنخفض الذين أكملوا الطلبات. Lv1-59٪ ، Lv2-19٪ ، Lv3-15٪.",
    },
    // 订单列表
    order: {
        toSubmit: 'لم يقدم',
        submitted: 'قدم',
        totalOrder: 'مجموع أوامر',
        commission: 'عمولة',
        timeLimit: 'مدة الولاية',
        orderDetail: 'تفاصيل النظام',
        orderNumber: 'رقم الطلب',
        orderTime: 'وقت النظام',
        commodityPrice: 'سعر الوحدة',
        quantityItems: 'كمية السلع',
        close: 'غلق',
        confirm: 'أكّد',
        submitOrder: 'تقديم طلب',
        noOrder: 'أمر مؤقت'
    },
    // 抢单
    stealOrder: {
        myBalance: 'بلدي الرصيد',
        completions: 'الانتهاء من عدد',
        numberJobs: 'عدد المهام',
        autoMatch: 'التلقائي مطابقة',
        todayEarning: 'مكاسب اليوم',
        todayOrder: 'أوامر اليوم',
        confirm: 'أكّد',
        choose: 'اختيار التاجر',
        match: 'مطابقة البضائع',
        message1: 'عدم كفاية الرصيد',
        message2: 'لم يقدم النظام',
        message3: 'ممنوع سرقة واحدة',
        message4: 'لا أوامر جديدة'
    },
    // 团队报告
    teamReport: {
        teamReport: 'تقرير الفريق',
        all: 'جميع البيانات',
        number: 'عدد الفرق',
        commissionTeam: 'فريق لجنة النظام',
        one: 'المستوى1',
        two: 'المستوى2',
        three: 'المستوى3'
    },
    // 我的
    my: {
        code: 'رمز التعميم',
        item1: 'شحن',
        item2: 'سحب',
        item3: 'سجل التغيير',
        item4: 'سجل النقدية',
        item5: 'سحب المعلومات',
        item6: 'سجل شحن',
        item7: 'إعلان المعلومات',
        item8: 'كلمة السر',
        item9: 'سحب'
    },
    topup: {
        copy: 'نسخ',
        record: 'سجل',
        paytype: 'طريقة الدفع',
        amountLimit: 'الحد من المبلغ',
        upload: 'تقديم شهادة الشحن',
        submit: 'تقديم',
        title: 'الرجاء تحميل قسيمة لقطة مضغوطة أدخل قيمة الشحن',
        money: 'يرجى إدخال المبلغ',
        exceed: 'لا يمكن أن يتجاوز حجم الصورة 1 م',
        tip: 'يمكن فقط تحميل ملفات JPG / بابوا نيو غينيا ، وليس أكثر من 1 متر'
    },
    withdrawal: {
        withdrawals: 'سحب',
        myBalance: 'بلدي الرصيد',
        usdt: 'usdt استخراج',
        withdrawalBalance: 'سحب المبلغ',
        determine: 'تحديد'
    },
    system: {
        systemNotify: 'نظام الإخطار',
        mail: 'رسالة داخلية',
        noData: 'بيانات مؤقتة'
    },
    withdrawalInfo: {
        withdrawalsInfo: 'سحب المعلومات',
        usdtAddress: 'عنوان USDT',
        modify: 'عدل'
    },
    withdrawalRecord: {
        withdrawalsRecord: 'سجل النقدية',
        applicationAmount: 'مبلغ الطلب',
        serviceCharge: 'رسوم المناولة',
        receipt: 'حساب',
        turnDown: 'يرجى الاتصال بخدمة العملاء إذا كان لديك أي أسئلة .'
    },
    accountRecord: {
        accountRecordTitle: 'سجل التغيير',
        changeType: 'نوع المعاملة',
        trading: 'صفقة',
        previousAmount: 'المبلغ السابق',
        changeAmount: 'حساب المبلغ',
        changeTime: 'حساب الوقت',
        commission: 'عودة خادمة'
    },
    topupRecord: {
        title:  'سجل شحن',
        success: 'شحن ناجحة'
    },
    password: {
        title: 'كلمة السر',
        loginPassword: 'كلمة السر',
        cashPassword: 'استخراج كلمة السر',
        oldPassword: 'كلمة السر القديمة',
        newPassword: 'كلمة السر الجديدة',
        confirmPassword: 'تأكيد كلمة المرور',
        confirm: 'أكّد',
        isEmpty: 'يرجى ملء كامل',
        same: 'تأكد من أن كلمة السر هي نفس كلمة المرور الجديدة',
        noCorrect: 'اسم المستخدم أو كلمة المرور غير صحيحة',
        pwdSix: 'كلمة السر تحتاج إلى ستة أو أكثر'
    },
    lgout: {
        message: 'تسجيل الدخول مرة أخرى بعد تسجيل الخروج ، تأكد من'
    }
}
