const { register } = require("@/api");

//德语
module.exports = {
    all: {
        success: 'Betrieb erfolgreich',
        error: 'Gescheitert.',
        cancel: 'Abbrechen',
        confirm: 'bestätigen',
        tip: 'Tipps',
        lackBalance: 'Tut mir leid, Ihr Kredit geht aus.',
        withdrawPwdErr: 'Passwort für die Auszahlung falsch',
        notComplete: 'Unvollständig erfordert ungerade Zahl'
    },
    login:{
        login: 'Anmelden',
        register: 'registrieren',
        placeholder1: 'Bitte geben Sie Ihr Login-Konto ein',
        placeholder2: 'Bitte geben Sie Ihr Login-Passwort ein'
    },
    register: {
        invitationCode: 'Einladungscode',
        phone: 'Telefonnummer',
        password: 'Passwort',
        confirmPassword: 'Passwort bestätigen',
        withdrawalPassword: 'Passwort für die Auszahlung',
        login: 'Anmelden',
        register: 'registrieren'
    },
      // 首页
      index: {
        myService: 'Mein Service',
        download: 'Download',
        help: 'Hilfe',
        partners: 'Kooperationspartner',
        selectLanguage: 'Sprache wählen',
        incomeCommission: 'Einnahmeprovision'
    },
    help: {
        title: 'Hilfe',
        question: "häufiges Problem",
        question1: 'Was ist AmazonBOT?',
        question2: 'AmazonBOT ist ein Marketing- und Promotionunternehmen, das globalen Amazon Online-Händlern hilft, mehr Bestellumsätze zu erzielen und Browsing-Daten im Amazon Online-Shop zu erhöhen. Wir verpflichten uns zu einem profitablen Marketing- und Promotionsmodell zwischen Amazon, Händlern und Verbrauchern.',
        question3: "Die Kombination der neuesten P2P-Blockchain-Technologie, die Geschäftspolitik, Verbraucher und Händler schnell über USDT (TRC20, ERC20) zu verbinden. Registrierte Nutzer erhalten Auftragsprovisionen, während Händler ihre Verkaufsdaten erhöhen. Das neueste Gewinnmodell unter dem Internet Blockchain Modell!",
        question4: "Was ist das Arbeitsprinzip von AmazonBOT?",
        question5: "Tägliches Feedback von Amazon zeigt die Produkte, die Verkäufe in Amazon steigern müssen. Benutzer müssen nur einfach auf die Bestellung mit einem Klick klicken, und das System generiert automatisch ein Bestellabonnement. Benutzer zahlen den Bestellbetrag über Blockchain USDT und erhalten tägliche Provision.",
        question6: "Warum gibt es einen Preisunterschied für die gleiche Währung zwischen zwei Transaktionen?",
        question7: "Alle Faktoren, die den freien Währungsfluss behindern, können Preisunterschiede verursachen, einschließlich der Geschwindigkeit der Währungsübertragung, der Netzwerkbedingungen, der Beschränkungen des Währungszugangs, der Anerkennung von Währungen durch Menschen in verschiedenen Regionen und sogar der Arten von Handelspaaren und Transaktionen, die von Börsen bereitgestellt werden. Daher kann es bei derselben Währung zu Preisunterschieden bei verschiedenen Transaktionen kommen.",
        question8: "4. Investitionsgewinn?",
        question9: "Je höher der Preis des Produktes, das Sie erhalten, desto höher ist der Gewinn Ihrer Bestellung. Inzwischen verteilt AmazonBOT zufällig große Provisionsaufträge.",
        trad: "Handelsregeln",
        trad1: "Das System generiert automatisch und verteilt täglich 60-Produktaufträge an die Benutzer. Benutzer schließen jede Auftragszahlung über USDT ab und erhalten eine Provision von 0,5%. Das System verteilt nach dem Zufallsprinzip große Provisionsaufträge.",
        trad2: "Nach Abschluss von 60-Bestellungen kann USDT abgehoben werden. (Wenn 60-Bestellungen nicht abgeschlossen sind, stoppt das System automatisch bis zur verbleibenden Bestellmenge des Tages)",
        top_up: "Wie kann ich aufladen?",
        top_up1: "Bevor Sie aufladen, binden Sie bitte Ihre Auszahlungsadresse (unterstützt TRC-20) USDT-Adresse. Sie können auf der APP oder Webseite auf den Aufladen-Button klicken und die Blockchain (TRC-20) auswählen, die Sie zum Aufladen verwenden.",
        withdraw: "Wie kann ich mich zurückziehen?",
        withdraw1: "Bevor Sie aufladen, binden Sie bitte Ihre Auszahlungsadresse (unterstützt TRC-20) USDT-Adresse. Sie können auf der APP oder Webseite auf den Aufladen-Button klicken und die Blockchain (TRC-20) auswählen, die Sie zum Aufladen verwenden.",
        invite: "Wie lade ich Freunde ein?",
        invite1: "Sie können sie über den Einladungslink oder den Einladungscode einladen und sie werden nach erfolgreicher Registrierung Mitglieder Ihres Teams.",
        invite2: "Wenn Sie Freunde einladen, sich anzuschließen, können Sie 30% des täglichen Umsatzes erhalten, wenn Sie Bestellungen mit Freunden auf niedrigem Niveau abschließen. Lv1-59%、Lv2-19%、Lv3-15% 。",
    },
    // 订单列表
    order: {
        toSubmit: 'Nicht eingereicht',
        submitted: 'Eingereicht',
        totalOrder: 'Gesamtbetrag der Bestellung',
        commission: 'Provision',
        timeLimit: 'Aufgabenfrist',
        orderDetail: 'Bestelldetails',
        orderNumber: 'Bestellnummer',
        orderTime: 'Bestellzeit',
        commodityPrice: 'Artikelpreise',
        quantityItems: 'Warenmenge',
        close: 'schließen',
        confirm: 'bestätigen',
        submitOrder: 'Bestellung aufgeben',
        noOrder: 'Im Moment keine Bestellungen'
    },
    // 抢单
    stealOrder: {
        myBalance: 'Mein Gleichgewicht',
        completions: 'Abgeschlossene Menge',
        numberJobs: 'Anzahl der Aufgaben',
        autoMatch: 'Automatisches Abgleich',
        todayEarning: 'Das heutige Ergebnis',
        todayOrder: 'Die heutigen Bestellungen',
        confirm: 'bestätigen',
        choose: 'Händler auswählen',
        match: 'Passende Produkte',
        message1: 'Tut mir leid, Ihr Kredit geht aus.',
        message2: 'Es gibt Bestellungen, die nicht eingereicht wurden',
        message3: 'Verbot von Greifbefehlen',
        message4: 'Es gibt keine neuen Bestellungen'
    },
    // 团队报告
    teamReport: {
        teamReport: 'Teambericht',
        all: 'Alle Daten',
        number: 'Anzahl der Teams',
        commissionTeam: 'Team Order Provision',
        one: 'Ebene 1',
        two: 'Ebene 2',
        three: 'Ebene 3'
    },
    // 我的
    my: {
        code: 'Aktionscode',
        item1: 'Aufladen',
        item2: 'Zeichnung',
        item3: 'Kontoänderungsdaten',
        item4: 'Auszahlungsdatum',
        item5: 'Auszahlungsinformationen',
        item6: 'Aufladungsaufzeichnung',
        item7: 'Informationsmitteilung',
        item8: 'Passwort',
        item9: 'abmelden'
    },
    topup: {
        copy: 'Kopie',
        record: 'Aufzeichnung',
        paytype: 'Zahlungsmethode',
        amountLimit: 'Höchstbetrag',
        upload: 'Präsentation des ladezertifikats',
        submit: 'Eingereicht werden',
        title: 'Bitte laden sie das ungültige dokument aus und geben sie den ladebetrag ein',
        money: 'Bitte zahlen eingeben.',
        exceed: 'Das bild darf nicht größer als 1M sein',
        tip: 'Nur JPG/PNG Dateien können hochgeladen werden, und sie sollten 1M nicht überschreiten'
    },
    withdrawal: {
        withdrawals: 'Zeichnung',
        myBalance: 'Mein Gleichgewicht',
        usdt: 'USDT-Rücknahme',
        withdrawalBalance: 'Auszahlungsbetrag',
        determine: 'Auszahlung bestätigen'
    },
    system: {
        systemNotify: 'Systembenachrichtigung',
        mail: 'Post',
        noData: 'Zur Zeit keine Daten verfügbar'
    },
    withdrawalInfo: {
        withdrawalsInfo: 'Auszahlungsinformationen',
        usdtAddress: 'USDT-Adresse',
        modify: 'modifizieren'
    },
    withdrawalRecord: {
        withdrawalsRecord: 'Auszahlungsdatum',
        applicationAmount: 'Antragsbetrag',
        serviceCharge: 'Servicegebühr',
        receipt: 'Nach Rechnung eingegangen',
        turnDown: 'Auszahlung abgelehnt: Die Auszahlungsinformationen sind falsch. Wenn Sie Fragen haben, wenden Sie sich bitte an den Kundenservice'
    },
    accountRecord: {
        accountRecordTitle: 'Kontoänderungsdatum',
        changeType: 'Transaktionsart',
        trading: 'Transaktion',
        previousAmount: 'Vorheriger Betrag',
        changeAmount: 'Kontoänderungsbetrag',
        changeTime: 'Kontowechselzeit',
        commission: 'Rabatt der Kommission'
    },
    topupRecord: {
        title:  'Aufladungsaufzeichnung',
        success: 'Aufladen erfolgreich'
    },
    password: {
        title: 'Passwort',
        loginPassword: 'Login Passwort',
        cashPassword: 'Passwort für die Auszahlung',
        oldPassword: 'Altes Passwort',
        newPassword: 'Neues Passwort',
        confirmPassword: 'Passwort bestätigen',
        confirm: 'bestätigen',
        isEmpty: 'Bitte füllen Sie das Formular vollständig aus',
        same: 'Passwort bestätigen und mit dem neuen Passwort konsistent halten',
        noCorrect: 'Benutzername oder Passwort falsch',
        pwdSix: 'Passwort muss sechs Zeichen oder mehr sein'
    },
    lgout: {
        message: 'Nach dem Abmelden müssen Sie sich erneut einloggen. Sind Sie sicher'
    }
}
