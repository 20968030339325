const { register } = require("@/api");

//荷兰语
module.exports = {
    all: {
        success: 'Operatie succesvol',
        error: 'mislukking',
        cancel: 'annuleren',
        confirm: 'bevestigen',
        tip: 'Tips',
        lackBalance: 'Onvoldoende saldo',
        withdrawPwdErr: 'Intrekking wachtwoord is onjuist',
        notComplete: 'Het onvoltooide moet enkelvoud zijn',
        notMessage: 'De gebruiker is niet gebonden aan de herroepingsinformatie'
    },
    login:{
        login: 'Inloggen',
        register: 'register',
        placeholder1: 'Voer uw login-account in',
        placeholder2: 'Voer uw login wachtwoord in'
    },
    register: {
        invitationCode: 'Uitnodigingscode',
        phone: 'telefoonnummer',
        password: 'wachtwoord',
        confirmPassword: 'Wachtwoord bevestigen',
        withdrawalPassword: 'Wachtwoord voor intrekking',
        login: 'Inloggen',
        register: 'register'
    },
      // 首页
      index: {
        myService: 'Mijn dienst',
        download: 'download',
        help: 'hulp',
        partners: 'samenwerkingspartner',
        selectLanguage: 'Taal kiezen',
        incomeCommission: 'Ontvangstcommissie'
    },
    help: {
        title: "Hulp",
        question: "Gemeenschappelijk probleem",
        question1: 'Wat is AmazonBOT?',
        question2: 'AmazonBOT is een marketing- en promotiebedrijf dat wereldwijde Amazon online verkopers helpt om meer orderverkopen te behalen en browsegegevens in de Amazon online winkel te verhogen. We zijn toegewijd aan een winstgevend marketing- en promotiemodel van drie partijen tussen Amazon, handelaren en consumenten.',
        question3: "Het combineren van de nieuwste P2P blockchain technologie, het zakelijke beleid om consumenten en handelaren snel te verbinden via USDT (TRC20, ERC20). Geregistreerde gebruikers ontvangen ordercommissies, terwijl verkopers hun verkoopgegevens verhogen. Het nieuwste winstmodel onder het internet blockchain model!",
        question4: "Wat is het werkprincipe van AmazonBOT?",
        question5: "Dagelijkse feedback van Amazon toont de producten die nodig zijn om de verkoop in Amazon te verhogen. Gebruikers hoeven slechts gemakkelijk op de bestelling te klikken met één klik, en het systeem zal automatisch een order abonnement genereren. Gebruikers betalen het bestelbedrag via blockchain USDT en ontvangen dagelijks commissie.",
        question6: "Waarom is er een prijsverschil voor dezelfde valuta tussen twee transacties?",
        question7: "Alle factoren die de vrije stroom van valuta belemmeren, kunnen prijsverschillen veroorzaken, waaronder valutaoverdrachtssnelheid, netwerkvoorwaarden, beperkingen voor toegang tot valuta, de erkenning van valuta door mensen in verschillende regio's en zelfs de soorten handelsparen en transacties die door beurzen worden verstrekt. Daarom kan dezelfde valuta prijsverschillen vertonen in verschillende transacties.",
        question8: "4. Investeringswinst?",
        question9: "Hoe hoger de prijs van het product dat u ontvangt, hoe hoger de winst op uw bestelling. Ondertussen verdeelt AmazonBOT willekeurig grote commissieorders.",
        trad: "Handelsregels",
        trad1: "Het systeem genereert automatisch en verdeelt elke dag 60 productorders aan gebruikers. Gebruikers voltooien elke orderbetaling via USDT en ontvangen een commissie van 0,5%. Het systeem verdeelt willekeurig grote commissieorders.",
        trad2: "Na het voltooien van 60-bestellingen, kan USDT worden ingetrokken. (Als 60-orders niet worden voltooid, zal het systeem automatisch stoppen tot de resterende ordehoeveelheid van de dag)",
        top_up: "Hoe kan ik opladen?",
        top_up1: "Voordat u oplaadt, bindt u uw opnameadres (ondersteund TRC-20) USDT-adres. U kunt klikken op de oplaadknop op de APP of webpagina en de blockchain (TRC-20) selecteren die u gebruikt om op te laden.",
        withdraw: "Hoe kan ik me terugtrekken?",
        withdraw1: "Voordat u oplaadt, bindt u uw opnameadres (ondersteund TRC-20) USDT-adres. U kunt klikken op de oplaadknop op de APP of webpagina en de blockchain (TRC-20) selecteren die u gebruikt om op te laden.",
        invite: "Hoe nodig ik vrienden uit?",
        invite1: "Je kunt ze uitnodigen via de uitnodigingslink of uitnodigingscode, en ze worden lid van je team na succesvolle registratie.",
        invite2: "Wanneer u vrienden uitnodigt om lid te worden, kunt u 30% van de dagelijkse inkomsten ontvangen van het voltooien van bestellingen met vrienden op laag niveau. Lv1-59%、Lv2-19%、Lv3-15% 。",
    },
    // 订单列表
    order: {
        toSubmit: 'Niet ingediend',
        submitted: 'Ingezonden',
        totalOrder: 'Totaal orderbedrag',
        commission: 'commissie',
        timeLimit: 'Taakdeadline',
        orderDetail: 'Ordergegevens',
        orderNumber: 'bestelnummer',
        orderTime: 'Ordertijd',
        commodityPrice: 'artikelprijzen',
        quantityItems: 'Hoeveelheid goederen',
        close: 'sluiten',
        confirm: 'bevestigen',
        submitOrder: 'bestelling plaatsen',
        noOrder: 'Geen bestellingen op dit moment'
    },
    // 抢单
    stealOrder: {
        myBalance: 'Mijn balans',
        completions: 'Voltooide hoeveelheid',
        numberJobs: 'Aantal taken',
        autoMatch: 'Automatische matching',
        todayEarning: 'De inkomsten van vandaag',
        todayOrder: 'Orders van vandaag',
        confirm: 'bevestigen',
        choose: 'Koophandel selecteren',
        match: 'Bijpassende producten',
        message1: 'Sorry, je krediet raakt op.',
        message2: 'Er zijn bestellingen die niet zijn ingediend',
        message3: 'Verbod op grijpborden',
        message4: 'Er zijn geen nieuwe bestellingen'
    },
    // 团队报告
    teamReport: {
        teamReport: 'Teamrapport',
        all: 'Alle gegevens',
        number: 'Aantal teams',
        commissionTeam: 'Team ordercommissie',
        one: 'Niveau 1',
        two: 'Niveau 2',
        three: 'Niveau 3'
    },
    // 我的
    my: {
        code: 'Promotiecode',
        item1: 'Opladen',
        item2: 'tekening',
        item3: 'Rekeningswijzigingen',
        item4: 'Opnamerecord',
        item5: 'Informatie over intrekking',
        item6: 'Oplaadrecord',
        item7: 'Kennisgeving',
        item8: 'wachtwoord',
        item9: 'afmelden'
    },
    topup: {
        copy: 'kopie',
        record: 'record',
        paytype: 'Betalingsmethode',
        amountLimit: 'Bedragslimiet',
        upload: 'U dient een bewijs van opwaarderen in',
        submit: 'omschrijving',
        title: 'Upload de oplaad screenshot voucher en voer het oplaad bedrag in',
        money: 'Gelieve het bedrag in te vullen',
        exceed: 'Foto\'s mogen niet groter zijn dan 1M',
        tip: 'Alleen JPG/PNG-bestanden kunnen worden geüpload en mogen niet hoger zijn dan 1M'
    },
    withdrawal: {
        withdrawals: 'tekening',
        myBalance: 'Mijn balans',
        usdt: 'Uittrekking van USDT',
        withdrawalBalance: 'Opnamebedrag',
        determine: 'Opname bevestigen'
    },
    system: {
        systemNotify: 'Systeemmelding',
        mail: 'post',
        noData: 'Geen gegevens beschikbaar op dit moment'
    },
    withdrawalInfo: {
        withdrawalsInfo: 'Informatie over intrekking',
        usdtAddress: 'USDT-adres',
        modify: 'wijzigen'
    },
    withdrawalRecord: {
        withdrawalsRecord: 'Opnamerecord',
        applicationAmount: 'Aanvraagbedrag',
        serviceCharge: 'Servicekosten',
        receipt: 'Ontvangen per rekening',
        turnDown: 'Als u vragen heeft, kunt u contact opnemen met de klantenservice.'
    },
    accountRecord: {
        accountRecordTitle: 'Rekeningswijziging record',
        changeType: 'Transactietype',
        trading: 'transactie',
        previousAmount: 'Vorig bedrag',
        changeAmount: 'Veranderingsbedrag van de rekening',
        changeTime: 'Tijd voor wijziging van de rekening',
        commission: 'Korting van de Commissie'
    },
    topupRecord: {
        title:  'Oplaadrecord',
        success: 'Opladen succesvol'
    },
    password: {
        title: 'wachtwoord',
        loginPassword: 'Login wachtwoord',
        cashPassword: 'Wachtwoord voor intrekking',
        oldPassword: 'Oud wachtwoord',
        newPassword: 'Nieuw wachtwoord',
        confirmPassword: 'Wachtwoord bevestigen',
        confirm: 'bevestigen',
        isEmpty: 'Vul het formulier volledig in',
        same: 'Wachtwoord bevestigen en consistent houden met het nieuwe wachtwoord',
        noCorrect: 'Gebruikersnaam of wachtwoord onjuist',
        pwdSix: 'Wachtwoord moet zes tekens of meer zijn'
    },
    lgout: {
        message: 'Controleer of het wachtwoord consistent moet zijn met het nieuwe wachtwoord. Nadat u bent uitgelogd, moet u opnieuw inloggen.'
    }
}
