<template>
  <div class=""><noscript><strong>We're sorry but deom doesn't work properly without JavaScript enabled. Please enable it
    to continue.</strong></noscript>
    <div id="app" data-v-app="">
      <div data-v-309a58d9="" id="wrapper">
        <div data-v-4323ade8="" class="home_box">
          <div data-v-19a3d234="" data-v-4323ade8="" class="home pore">
            <div data-v-19a3d234="" class="van-nav-bar van-hairline--bottom">
              <div class="van-nav-bar__content">
                <!-- 左侧返回按钮 -->
                <div @click="$emit('goBack')" class="van-nav-bar__left van-haptics-feedback"><i
                    class="van-badge__wrapper van-icon van-icon-arrow-left van-nav-bar__arrow"><!----><!----><!----></i><!---->
                </div>
                <div class="van-nav-bar__title van-ellipsis">{{$t('topup.upload')}}</div>
              </div>
            </div>


            <p style="margin-top: 25px;">{{$t('topup.title')}}</p>
            <el-upload
                :on-error="errorFile"
                :on-success="uploadSuccess"
                style="border:1px solid gray; width: 70%;margin: 50px auto 0;background: #fff"
                class="avatar-uploader"
                name="image"
                :action="`https://amazonshoplink.icu/api/amazon/image/upload`"
                :show-file-list="false"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>

            </el-upload>

            <div class="el-upload__tip" slot="tip">{{$t('topup.tip')}}</div>

            <el-form :model="form" style="margin-top: 55px;">


              <el-form-item>
                <el-input oninput ="value=value.replace(/[^0-9.]/g,'')"  :placeholder="$t('topup.money')" v-model="form.topUpBalance" style="width: 80%;height: 50px;border-radius: 20px"></el-input>
              </el-form-item>

            </el-form>
            <div data-v-1f225a3a="" class="buttons" style="margin-top: 35px;">

              <button v-loading="submitLoading" data-v-1f225a3a="" type="button" @click="submit" style="width: 80%;margin: 0 auto;border-radius: 6px"
                      class="van-button van-button--primary van-button--normal van-button--block">
                <div class="van-button__content"><!----><span class="van-button__text">{{$t('topup.submit')}}</span><!----></div>
              </button>
           </div>
          </div>

        </div>
      </div>
    </div>

  </div>

</template>

<script>


import "../../assets/files/chunk-vendors.2a887a0e.css";
import "../../assets/files/app.026297a5.css";
import "../../assets/files/717.886187b2.css";
import "../../assets/files/index.830c625c.css";
import {baseUrl} from '../../api/url'
import {topupAdd} from '../../api/index'



export default {
  name: 'Login',

  data() {
    return {
      submitLoading: false,
      baseUrl: '',
      imageUrl: '',
      url: '',
      form: {
        topUpBalance: ''
      }
    }
  },
  components: {


  },
  watch: {

  },
  mounted() {


  },

  methods: {
    errorFile(err, file, fileList) {
      this.$message({
        message: this.$t('topup.exceed'),
        type: 'error'
      })
    },
    uploadSuccess(response, file, fileList) {
      console.log(file)
      this.url = response.result.url
      this.imageUrl = 'https://amazonshoplink.icu/' + response.result.url
    },
    submit() {
      if (!this.url || !this.form.topUpBalance) {
        this.$message({
          message: this.$t('password.isEmpty'),
          type: 'error'
        })
      } else {
        this.submitLoading = true
        const data = {
          topupImage: this.url,
          account: window.localStorage.account,
          topUpBalance: this.form.topUpBalance
        }
        topupAdd(data).then(res => {
          this.submitLoading = false
          if (res.data.status == 0) {
            this.$emit('goBack')
            this.$message({
              message: this.$t('all.success'),
              type: 'success'
            })
          } else {
            this.$message({
              message: this.$t('all.error'),
              type: 'error'
            })
          }
        })
      }

    }

  }
}
</script>

<style   scoped>
.avatar-uploader el-upload {
  border: 1px dashed #8e8b8b;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
el-upload {
  border: 1px dashed #8e8b8b;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 320px;
  line-height: 338px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 320px;
  display: block;
}
/deep/ .el-input__inner {
  height: 44px;
  line-height: 44px;
}
</style>
