const { register } = require("@/api");

//日语
module.exports = {
    all: {
        success: '操作は成功しました',
        error: '失敗です',
        cancel: 'キャンセル',
        confirm: '確認する',
        tip: 'ヒント',
        lackBalance: '残高が不足しています',
        withdrawPwdErr: '暗証番号が間違っています',
        notComplete: '未完成には単数が必要です',
        notMessage: '利用者は引き出し情報を持っていません'
    },
    login:{
        login: 'ログイン',
        register: '登録する',
        placeholder1: 'ログインアカウントを入力してください',
        placeholder2: 'ログインパスワードを入力してください'
    },
    register: {
        invitationCode: '招待コード',
        phone: '電話番号',
        password: 'パスワード',
        confirmPassword: 'パスワードを認証する',
        withdrawalPassword: 'パスワードを撤回する',
        login: 'ログイン',
        register: '登録する'
    },
      // 首页
      index: {
        myService: '私のサービス',
        download: 'ダウンロード',
        help: 'ヘルプ',
        partners: 'パートナー',
        selectLanguage: '言語を選択してください',
        incomeCommission: '収益コミッション'
    },
    help: {
        title: "ヘルプ",
        question1: "1.AmazonBOTとは何ですか？",
        question2: "AmazonBOTは、世界中のAmazonネットワークの販売者が注文販売量を増やし、Amazonネットワークストア内のブラウジングデータを増やすためのマーケティングプロモーション会社です。私たちはAmazonと販売者と消費者の間の三方の利益を追求しています。",
        question3: "2.AmazonBOTはどのように機能しますか？",
        question4: "AmazonBOTは最新のP2Pブロックチェーン技術を組み合わせて、消費者と販売者をUSDT（TRC20、ERC20）で迅速に接続し、登録したユーザーは注文コミッションを獲得し、販売者は店舗の販売データを増やします。インターネットブロックチェーンモードの最新の収益モデル！",
        question5: "3.なぜ注文がマッチングされないのですか？",
        question6: "注文がマッチングされない場合は、注文がマッチングされるまでお待ちください。注文がマッチングされると、注文がマッチングされたことを通知するメッセージが表示されます。注文がマッチングされない場合は、注文がマッチングされるまでお待ちください。注文がマッチングされると、注文がマッチングされたことを通知するメッセージが表示されます。注文がマッチングされない場合は、注文がマッチングされるまでお待ちください。注文がマッチングされると、注文がマッチングされたことを通知するメッセージが表示されます。注文がマッチングされない場合は、注文がマッチングされるまでお待ちください。注文がマッチングされると、注文がマッチングされたことを通知するメッセージが表示されます。注文がマッチングされない場合は、注文がマッチングされるまでお待ちください。注文がマッチングされると、注文がマッチングされたことを通知するメッセージが表示されます。",
        question7: "4.投資収益？",
        question8: "投資収益は、投資額に応じて毎日の収益を獲得することができます。投資額が大きいほど、注文からの収益が大きくなります。同時に、AmazonBOTは大きな注文のコミッションをランダムに分配します。",
        question9: "5.注文がマッチングされないのはなぜですか？",
        trade: "取引ルール",
        trade1: "1.注文を提出すると、注文がマッチングされるまでお待ちください。注文がマッチングされると、注文がマッチングされたことを通知するメッセージが表示されます。注文がマッチングされない場合は、注文がマッチングされるまでお待ちください。注文がマッチングされると、注文がマッチングされたことを通知するメッセージが表示されます。注文がマッチングされない場合は、注文がマッチングされるまでお待ちください。注文がマッチングされると、注文がマッチングされたことを通知するメッセージが表示されます。",
        trade2: "2.注文がマッチングされると、注文がマッチングされたことを通知するメッセージが表示されます。注文がマッチングされない場合は、注文がマッチングされるまでお待ちください。注文がマッチングされると、注文がマッチングされたことを通知するメッセージが表示されます。",
        top_up: "どのようにチャージしますか？",
        top_up1: "1.チャージする前に、まず出金アドレス（TRC-20）をバインドしてください。アプリまたはウェブサイトで「チャージ」ボタンをクリックし、チャージするブロックチェーン（TRC-20）を選択します。",
        withdraw: "どのように出金しますか？",
        withdraw1: "1.チャージする前に、まず出金アドレス（TRC-20）をバインドしてください。アプリまたはウェブサイトで「チャージ」ボタンをクリックし、チャージするブロックチェーン（TRC-20）を選択します。",
        invite: "どのように友達を招待しますか？",
        invite1: "招待リンクまたは招待コードを使用して招待できます。招待された人は登録に成功すると、あなたのチームメンバーになります。",
        invite2: "友達を招待すると、低レベルの友達が毎日注文を完了する収入の30%を得ることができます。 Lv1-59%、Lv2-19%、Lv3-15%。",
    },
    // 订单列表
    order: {
        toSubmit: '未提出',
        submitted: '提出済み',
        totalOrder: '注文合計',
        commission: '手数料',
        timeLimit: 'タスクの制限時間',
        orderDetail: '注文の詳細',
        orderNumber: '注文番号',
        orderTime: '注文時間',
        commodityPrice: '商品単価',
        quantityItems: '製品数量',
        close: '閉鎖',
        confirm: '確認する',
        submitOrder: '注文を送信する',
        noOrder: 'まだ注文はありません'
    },
    // 抢单
    stealOrder: {
        myBalance: '私の残高',
        completions: '完了数',
        numberJobs: 'タスクの数',
        autoMatch: '自動マッチング',
        todayEarning: '今日の収益',
        todayOrder: '今日の注文',
        confirm: '確認する',
        choose: '加盟店の選択',
        match: '適合する製品',
        message1: '残高不足',
        message2: '未送信の注文があります',
        message3: '注文の取り込みは禁止されています',
        message4: '新規注文はありません'
    },
    // 团队报告
    teamReport: {
        teamReport: 'チームレポート',
        all: 'すべてのデータ',
        number: 'チーム数',
        commissionTeam: 'チームオーダーコミッション',
        one: 'レベル1',
        two: 'レベル2',
        three: 'レベル3'
    },
    // 我的
    my: {
        code: 'プロモーションコード',
        item1: '補充する',
        item2: 'お金を引き出す',
        item3: 'アカウント変更記録',
        item4: '出金記録',
        item5: '出金情報',
        item6: 'リチャージ記録',
        item7: 'お知らせのお知らせ',
        item8: 'パスワード',
        item9: 'やめる'
    },
    topup: {
        copy: 'コピー',
        record: '記録',
        paytype: '支払方法',
        amountLimit: '金額制限',
        upload: '請求書の提出です',
        submit: '提出します',
        title: 'チャージのスクリーンショットをアップロードして、チャージ金額を入力します。',
        money: '金額を入力願います。',
        exceed: '画像サイズは1Mを超えてはいけません',
        tip: '1 M以下のjpg/pngファイルのみアップロードできます'
    },
    withdrawal: {
        withdrawals: '引き出し',
        myBalance: '私の残高',
        usdt: 'USDTの出金',
        withdrawalBalance: '出金額',
        determine: '出金の確認'
    },
    system: {
        systemNotify: 'システム通知',
        mail: 'サイトメッセージ',
        noData: 'まだデータがありません'
    },
    withdrawalInfo: {
        withdrawalsInfo: '出金情報',
        usdtAddress: 'USDTアドレス',
        modify: '改訂'
    },
    withdrawalRecord: {
        withdrawalsRecord: '出金記録',
        applicationAmount: '申請金額',
        serviceCharge: '手数料',
        receipt: 'レシート',
        turnDown: '引き出し却下：引き出し情報に誤りがあります。ご質問があればカスタマーサービスまでお問い合わせください'
    },
    accountRecord: {
        accountRecordTitle: 'アカウント変更記録',
        changeType: '取引タイプ',
        trading: '貿易',
        previousAmount: '以前の金額',
        changeAmount: '口座変更金額',
        changeTime: 'アカウント変更時期',
        commission: 'リベート'
    },
    topupRecord: {
        title:  'リチャージ記録',
        success: 'リチャージ成功'
    },
    password: {
        title: 'パスワード',
        loginPassword: 'ログインパスワード',
        cashPassword: 'パスワードを撤回する',
        oldPassword: '以前のパスワード',
        newPassword: '新しいパスワード',
        confirmPassword: 'パスワードを認証する',
        confirm: '確認する',
        isEmpty: '完全に入力してください',
        same: '確認パスワードは新しいパスワードと一致している必要があります',
        noCorrect: 'ユーザー名またはパスワードが間違っています',
        pwdSix: 'パスワードは6桁以上必要です'
    },
    lgout: {
        message: 'ログアウト後に再度ログインする必要がありますか?'
    }
}
